/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        aboutFeatured {
          title
          aboutDescription
          aboutAlign
          aboutImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        timelineContainer {
          hideTimeline
          timelineText
          timeline {
            jobRole
            companyName
            working
            startDate(formatString: "MMMM YYYY")
            endDate(formatString: "MMMM YYYY")
            location
            description
          }
        }
        educationContainer {
          hideEducation
          educationText
          education {
            universityName
            group
            studying
            startDate(formatString: "MMMM YYYY")
            endDate(formatString: "MMMM - YYYY")
            location
            description
          }
        }
        skillsContainer {
          hideSkills
          skillsText
          skills {
            skillName
          }
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your page data
  const { frontmatter, html } = markdownRemark

  const aboutData = frontmatter.aboutFeatured

  const hasImage = getSrc(aboutData.aboutImage).includes("spacer.png")
    ? ""
    : aboutData.aboutImage.childImageSharp.gatsbyImageData

  const aboutImage = <GatsbyImage image={hasImage} alt="" />

  // timelime
  const timelineData = frontmatter.timelineContainer
  const timelineName = timelineData.timelineText
    ? timelineData.timelineText
    : "My journey"
  const timelineInfo = timelineData.timeline.map((info, index) => {
    const startdate = info.startDate === "0000-01-01" ? "" : info.startDate
    const enddate = info.endDate === "0000-01-01" ? "" : info.endDate
    return (
      <div sx={aboutStyles.timelineCard} key={"eTimeline" + index}>
        {info.jobRole ? <h3 sx={aboutStyles.jobRole}>{info.jobRole}</h3> : ""}
        <div sx={aboutStyles.timelineMeta}>
          {info.companyName ? (
            <p sx={aboutStyles.companyName}>{info.companyName}</p>
          ) : (
            ""
          )}
          {info.jobRole ? (
            <div sx={aboutStyles.date}>
              <span>{startdate}</span>
              <span sx={aboutStyles.endDate}>
                {info.working === true ? "Present" : enddate}
              </span>
            </div>
          ) : (
            ""
          )}

          {info.location ? (
            <p sx={aboutStyles.location}>{info.location}</p>
          ) : (
            ""
          )}
        </div>
        {info.description ? (
          <p sx={aboutStyles.description}>{info.description}</p>
        ) : (
          ""
        )}
      </div>
    )
  })

  // Education
  const educationData = frontmatter.educationContainer
  const myEducationTitle = educationData.educationText
    ? educationData.educationText
    : "My education"
  const educationInfo = educationData.education.map((details, index) => {
    const startdate =
      details.startDate === "0000-01-01" ? "" : details.startDate
    const enddate = details.endDate === "0000-01-01" ? "" : details.endDate
    return (
      <div sx={aboutStyles.timelineCard} key={"eduTimeline" + index}>
        {details.universityName ? (
          <h3 sx={aboutStyles.jobRole}>{details.universityName}</h3>
        ) : (
          ""
        )}
        <div sx={aboutStyles.timelineMeta}>
          {details.group ? (
            <p sx={aboutStyles.companyName}>{details.group}</p>
          ) : (
            ""
          )}
          {details.universityName ? (
            <div sx={aboutStyles.date}>
              <span>{startdate}</span>
              <span sx={aboutStyles.endDate}>
                {details.studying === true ? "Present" : enddate}
              </span>
            </div>
          ) : (
            ""
          )}
          {details.location ? (
            <p sx={aboutStyles.location}>{details.location}</p>
          ) : (
            ""
          )}
        </div>
        {details.description ? (
          <p sx={aboutStyles.location}>{details.description}</p>
        ) : (
          ""
        )}
      </div>
    )
  })

  // Skills
  const skillData = frontmatter.skillsContainer
  const skillsName = skillData.skillsText ? skillData.skillsText : "Skills"
  const skills = skillData.skills.map((skill, index) => (
    <div sx={aboutStyles.skills} key={"skill" + index}>
      {skill.skillName ? skill.skillName : ""}
    </div>
  ))

  const content = (
    <div>
      <div>
        {aboutData.title ? <h1 sx={aboutStyles.h1}>{aboutData.title}</h1> : ""}
        {aboutData.aboutDescription ? (
          <p sx={aboutStyles.firstPara}>{aboutData.aboutDescription}</p>
        ) : (
          ""
        )}
      </div>
      <div sx={aboutStyles.body} dangerouslySetInnerHTML={{ __html: html }} />
      {timelineData.hideTimeline === false ? (
        ""
      ) : (
        <div sx={aboutStyles.sectionTitle}>
          <h2>{timelineName}</h2>
          {timelineInfo}
        </div>
      )}

      {educationData.hideEducation === false ? (
        ""
      ) : (
        <div>
          <h2 sx={aboutStyles.sectionTitle}>{myEducationTitle}</h2>
          {educationInfo}
        </div>
      )}
      {skillData.hideSkills === false ? (
        ""
      ) : (
        <div sx={aboutStyles.sectionTitle}>
          <h2>{skillsName}</h2>
          {skills}
        </div>
      )}
    </div>
  )

  return (
    <Layout sx={aboutStyles.aboutContainer}>
      <Seo
        title={frontmatter.aboutFeatured.title}
        description={frontmatter.aboutFeatured.aboutDescription}
      />
      {aboutData.aboutAlign === "left" ? (
        <div sx={aboutStyles.gridLeft}>
          <div>
            <div sx={aboutStyles.image}>{aboutImage}</div>
          </div>
          <div sx={aboutStyles.content}>{content}</div>
        </div>
      ) : (
        <div sx={aboutStyles.gridRight}>
          <div sx={aboutStyles.orderOne}>
            <div sx={aboutStyles.image}>{aboutImage}</div>
          </div>
          <div sx={aboutStyles.content}>{content}</div>
          <div sx={aboutStyles.orderTwo}>
            <div sx={aboutStyles.image}>{aboutImage}</div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default AboutPage

const aboutStyles = {
  aboutContainer: {
    variant: "variants.section",
    pt: [4, 4, 7],
  },
  gridLeft: {
    variant: "variants.container",
    minHeight: "75vh",
    display: ["block", "grid"],
    gridGap: "64px",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "auto 75ch"],
    px: ["20px", "20px", "40px"],
  },
  gridRight: {
    variant: "variants.container",
    minHeight: "75vh",
    display: ["block", "block", "block", "grid"],
    gridGap: "64px",
    gridTemplateColumns: ["", "", "", "1fr 1fr", "75ch auto"],
    px: ["20px", "20px", "40px"],
  },
  image: {
    position: "sticky",
    top: "100px",
    mb: [6, 7, 0],
    borderRadius: "12px",
    overflow: "hidden",
    img: {
      display: "block",
      maxWidth: "100%",
      width: "100%",
    },
  },
  content: {
    maxWidth: ["auto", "auto", "75ch"],
    margin: ["0", "0", "0 auto"],
    color: "global.textColor",
    "h1":{
      fontSize: [5, 6, 7],
      fontWeight: "600",
      letterSpacing: "1px"
    },
    "h1, h2, b, i, em, strong": {
      color: "global.headingColor",
    },
    a: {
      color: "global.primarylinkColor",
      "&:hover": {
        color: "global.primaryLinkHoverColor",
      },
    },
  },
  body: {
    variant: "variants.markdown",
  },
  h1: {
    fontSize: 6,
    mt: 0,
    mb: [4],
  },
  firstPara: {
    fontSize: 4,
    mt: 0,
    mb: 6,
  },
  orderOne: {
    display: ["block", "block", "block", "none"],
  },
  orderTwo: {
    display: ["none", "none", "none", "block"],
  },

  // Timeline, Journey and Education Details
  jobRole: {
    fontSize: 3,
    mb: 0,
    color: "global.timelineHeading"
  },
  timelineMeta: {
    color: "global.mutedColor",
    fontSize: 2,
    my: 2,
  },
  companyName: {
    color: "global.textColor",
    m: 0,
  },
  date: {
    py: 1,
  },
  endDate: {
    "&::before": {
      content: "'—'",
      mx: 2,
    },
  },
  location: {
    my: 0,
  },
  description: {
    color: "global.textColor",
    fontSize: 3,
  },

  //skills
  skills: {
    // variant: "variants.tag",
  },
  timelineCard: {
    my: 5,
  },
  sectionTitle: {
    mt: 6,
  },
}
